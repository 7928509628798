import React, { useState } from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Switch } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';

import { FetchHandler } from '../../../util/fetchHandler';
import MailObject from '../../../Models/MailModel/MailObject';
import { ForumMode } from '../../../util/forumMode';

export class MailModalProps {
    public toggleModal: (val: boolean) => void;
    public forumMode: ForumMode;
    public mail: MailObject;
}

const MailModal = ({ toggleModal, forumMode, mail }: MailModalProps) => {
    const rewards = useAppSelector(state => state.rewardsCache.rewards)
        .filter(reward => reward.rewardParentID === "MANUAL");

    const [playerId, setPlayerId] = useState("");
    const [title, setTitle] = useState(mail ? mail.title : "");
    const [content, setContent] = useState(mail ? mail.content : "");
    const [mailRewards, setMailRewards] = useState<Array<string>>(mail ? 
        mail.rewards.map(cachedRewardId => rewards.find(r => r.rewardID == cachedRewardId).rewardName) 
        : []);

    const CreateMail = () => {
        const newMail = new MailObject();

        let rewardsToIds = [];
        mailRewards.forEach(rewardName => {
            rewardsToIds.push(rewards.find(r => r.rewardName === rewardName).rewardID);
        });

        newMail.id = mail ? mail.id : null;
        newMail.content = content;
        newMail.created = new Date();
        newMail.rewards = rewardsToIds;
        newMail.title = title;

        return newMail;
    }

    const SaveMail = () => {
        const newMail = CreateMail();

        FetchHandler.CreateMailRequest(newMail, false)
            .then(() => FetchHandler.FetchMails())
            .catch(console.error);

        toggleModal(false);
    }

    const EditMail = () => {
        const newMail = CreateMail();

        FetchHandler.EditMailRequest(newMail, false)
            .then(() => FetchHandler.FetchMails())
            .catch(console.error);

        toggleModal(false);
    }

    const SendToPlayer = () => {
        const newMail = CreateMail();

        FetchHandler.SendCustomMailToPlayerRequest(newMail, playerId, false)
            .then(() => FetchHandler.FetchMails())
            .catch(console.error);

        toggleModal(false);
    }

    const handleChangeForRewardUpdate = (event: SelectChangeEvent<typeof mailRewards>) => {
        const {
          target: { value },
        } = event;

        setMailRewards(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const GetSaveButton = () => {
        switch(forumMode) {
            case ForumMode.Create:
                return <Button onClick={() => { SaveMail() }}>Create</Button>;
            case ForumMode.Edit:
                return <Button onClick={() => { EditMail() }}>Edit</Button>;
            case ForumMode.Custom:
                return <Button onClick={() => { SendToPlayer() }}>Send To Player</Button>;
        }
    }

    return (
        <Dialog fullWidth={true} scroll="paper" sx={{ margin: "auto" }} onClose={() => toggleModal(false)} open={true}>
            <h3 style={{ margin: "20px", textAlign: "center" }}>{forumMode === ForumMode.Create ? "Create Mail" : "Edit Mail"}</h3>

            <Card>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                    <TextField sx={{ margin: "10px", width: "350px" }} label="Title" onChange={(ev) => setTitle(ev.target.value)} value={title} placeholder='Title' />
                    {
                        forumMode === ForumMode.Custom ? 
                            <TextField sx={{ margin: "10px", width: "350px" }} onChange={(ev) => setPlayerId(ev.target.value)} value={playerId} placeholder='PlayFab ID' /> :
                            <></>
                    }
                    <TextField rows={4} multiline sx={{ margin: "10px", width: "500px" }} label="Content" onChange={(ev) => setContent(ev.target.value)} value={content} />
                    <FormControl sx={{ m: 1, width: "350px" }}>
                        <InputLabel id="reward-selection">Rewards</InputLabel>
                        <Select
                            id={"reward-selection"}
                            multiple
                            value={mailRewards}
                            onChange={handleChangeForRewardUpdate}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {rewards.map((reward) => (
                                <MenuItem key={reward.itemID} value={reward.rewardName}>
                                    <Checkbox checked={mailRewards.includes(reward.rewardName)} />
                                    <ListItemText primary={reward.rewardName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </Card>

            <DialogActions>
                <Button onClick={() => toggleModal(false)}>Close</Button>
                {
                    GetSaveButton()
                }
            </DialogActions>

            <div style={{ minWidth: "100%" }}></div>
        </Dialog>
    );
}

export { MailModal };