export default class MailObject {
    public id: string;

    public created: Date;

    public title: string;

    public content: string;

    public rewards: Array<string> = new Array<string>();
}