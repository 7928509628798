import { ThunkDispatch } from "@reduxjs/toolkit";
import BattlePassObject from "../Models/BattlePassModel/BattlePassObject";
import MissionObject from "../Models/MissionModel/MissionObject";
import { ContentResultResponse } from "../Models/ResponseModels/ContentResultResponse";
import { RewardObject } from "../Models/RewardsModel/RewardObject";
import { deletePayload } from "../store/authenticator/Authenticator";
import { addBattlePass, addBattlePasses, editBattlePass, removeBattlePass } from "../store/battlePass/BattlePassCache";
import { useAppDispatch } from "../store/hooks";
import { addMission, addMissions, editMission, removeMission } from "../store/missions/MissionCache";
import { addLevel, removeLevel, setBundles, setCatalogItems, setCurrencies, setLevels } from "../store/playfab/PlayFabDataCache";
import { addReward, addRewards, editReward, removeReward } from "../store/rewards/RewardsCache";
import { RequestType, requestJSONUnknown, requestJson } from "./request";
import { RewardProfile } from "../Models/RewardsModel/RewardProfile";
import { addProfile, addProfiles, editProfile, removeProfile } from "../store/profiles/RewardProfilesCache";
import { setLoading } from "../store/loading/LoadingCache";
import { BattlePassSettings } from "../Models/BattlePassModel/BattlePassSettings";
import LevelStage from "../Models/LevelModel/LevelStage";
import LevelSettings from "../Models/LevelModel/LevelSettings";
import MailObject from "../Models/MailModel/MailObject";
import { addmail, addmails, editMail, removeMail } from "../store/mail/MailCache";

/**
 * @description A better fetcher that allows to load required dependencies without copy & pasting codeeee
 */
export class FetchHandler {

    private static dispatch: ThunkDispatch<any, any, any>;

    public static readonly setupDispatch = () => {
        this.dispatch = useAppDispatch();
    }

    private static disableLoading = (disable: boolean) => {
        if (disable) {
            this.dispatch(setLoading(false));
        }
    }

    public static readonly getBattlepasses = (disableLoadingAfterFinish: boolean = true) => new Promise<BattlePassObject[]>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("BattlePass/FetchAllBattlePasses", RequestType.GET)
            .then(battlePasses => {

                //This needs to be converted, otherwise methods of the class won't be populated
                const convertedPasses = (battlePasses as Array<BattlePassObject>)
                    .map(pass => Object.assign(new BattlePassObject(), pass));

                this.dispatch(addBattlePasses(convertedPasses));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(convertedPasses);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly getMissions = (disableLoadingAfterFinish: boolean = true) => new Promise<MissionObject[]>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<MissionObject[]>(Array<MissionObject>, "BattlePass/FetchAllMissions", RequestType.GET)
            .then(missions => {
                this.dispatch(addMissions(missions));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(missions);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly getRewards = (disableLoadingAfterFinish: boolean = true) => new Promise<RewardObject[]>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<RewardObject[]>(Array<RewardObject>, "BattlePass/FetchAllRewards", RequestType.GET)
            .then(rewards => {
                this.dispatch(addRewards(rewards));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(rewards);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly getPlayFabContent = (disableLoadingAfterFinish: boolean = true) => new Promise<ContentResultResponse>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson(ContentResultResponse, "PlayFab/FetchContent", RequestType.GET)
            .then((res) => {
                this.dispatch(setCatalogItems(res.catalogItems));
                this.dispatch(setCurrencies(res.virtualCurrencies));
                this.dispatch(setBundles(res.bundles));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly getValidationToken = (disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("BattlePass/ValidateToken", RequestType.GET)
            .then((res) => {
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(deletePayload());
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly getRewardProfiles = (disableLoadingAfterFinish: boolean = true) => new Promise<RewardProfile[]>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("BattlePass/FetchAllRewardProfiles", RequestType.GET)
            .then(profiles => {

                const convertedProfiles = (profiles as Array<RewardProfile>)
                    .map(profile => Object.assign(new RewardProfile(), profile));

                this.dispatch(addProfiles(convertedProfiles));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(convertedProfiles);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly EditRewardRequest = (reward: RewardObject, disableLoadingAfterFinish: boolean = true) => new Promise<RewardObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<RewardObject>(RewardObject, 'BattlePass/UpdateReward', RequestType.POST, reward)
            .then((reward) => {
                this.dispatch(editReward(reward))
                this.disableLoading(disableLoadingAfterFinish);

                resolve(reward);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly CreateRewardRequest = (reward: RewardObject, disableLoadingAfterFinish: boolean = true) => new Promise<RewardObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown('BattlePass/AddNewReward', RequestType.POST, reward)
            .then(guid => {
                reward.rewardID = guid as string;
                this.dispatch(addReward(reward));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(reward);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly DeleteRewardRequest = (reward: RewardObject, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("BattlePass/DeleteReward", RequestType.POST, reward.rewardID)
            .then((res) => {
                this.dispatch(removeReward(reward));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly CreateBattlepassRequest = (settings: BattlePassSettings, disableLoadingAfterFinish: boolean = true) => new Promise<BattlePassObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<BattlePassObject>(BattlePassObject, 'BattlePass/AddNewBattlePass', RequestType.POST, settings)
            .then(battlepass => {
                this.dispatch(addBattlePass(battlepass));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(battlepass);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly EditBattlepassRequest = (battlepass: BattlePassObject, disableLoadingAfterFinish: boolean = true) => new Promise<BattlePassObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<BattlePassObject>(BattlePassObject, 'BattlePass/EditBattlePass', RequestType.POST, battlepass)
            .then((battlepass) => {
                this.dispatch(editBattlePass(battlepass));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(battlepass);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly DeleteBattlepassRequest = (battlepass: BattlePassObject, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("BattlePass/DeleteBattlePass", RequestType.POST, battlepass.id)
            .then((res) => {
                this.dispatch(removeBattlePass(battlepass));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly DeleteRewardProfileRequest = (profile: RewardProfile, disableLoadingAfterFinish: boolean = true) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("BattlePass/DeleteRewardProfile", RequestType.POST, profile.profileID)
            .then(() => {
                this.dispatch(removeProfile(profile));
            })
            .catch(console.error);
    }

    public static readonly CreateProfileRequest = (profile: RewardProfile, disableLoadingAfterFinish: boolean = true) => new Promise<RewardProfile>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown('BattlePass/AddNewRewardProfile', RequestType.POST, profile)
            .then(guid => {
                profile.profileID = guid as string;
                this.dispatch(addProfile(profile));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(profile);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly EditProfileRequest = (profile: RewardProfile, disableLoadingAfterFinish: boolean = true) => new Promise<RewardProfile>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<RewardProfile>(RewardProfile, 'BattlePass/UpdateRewardProfile', RequestType.POST, profile)
            .then((res) => {
                this.dispatch(editProfile(profile));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(profile);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly CreateMissionRequest = (mission: MissionObject, disableLoadingAfterFinish: boolean = true) => new Promise<MissionObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown('BattlePass/AddNewMission', RequestType.POST, mission)
            .then(guid => {
                mission.missionGUID = guid as string;
                this.dispatch(addMission(mission));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(mission);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly EditMissionRequest = (mission: MissionObject, disableLoadingAfterFinish: boolean = true) => new Promise<MissionObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<MissionObject>(MissionObject, 'BattlePass/UpdateMission', RequestType.POST, mission)
            .then((res) => {
                this.dispatch(editMission(mission));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly DeleteMissionRequest = (mission: MissionObject, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown('BattlePass/DeleteMission', RequestType.POST, mission.missionGUID)
            .then((res) => {
                this.dispatch(removeMission(mission));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly getLevels = (disableLoadingAfterFinish: boolean = true) => new Promise<LevelStage[]>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<LevelStage[]>(Array<LevelStage>, "BattlePass/FetchLevels", RequestType.GET)
            .then(levels => {
                this.dispatch(setLevels(levels));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(levels);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly CreateLevelStageRequest = (settings: LevelSettings, disableLoadingAfterFinish: boolean = true) => new Promise<LevelStage>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown('BattlePass/CreateNewStage', RequestType.POST, settings)
            .then(level => {
                this.dispatch(addLevel(level));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(level);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly DeleteLevelStageRequest = (levelStage: LevelStage, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("BattlePass/DeleteLevelStage", RequestType.POST, levelStage.id)
            .then((res) => {
                this.dispatch(removeLevel(levelStage));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly FetchMails = (disableLoadingAfterFinish: boolean = true) => new Promise<MailObject[]>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<MailObject[]>(Array<MailObject>, "Mail/GetMails", RequestType.GET)
            .then((res) => {
                this.dispatch(addmails(res));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly CreateMailRequest = (mail: MailObject, disableLoadingAfterFinish: boolean = true) => new Promise<MailObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<MailObject>(MailObject, "Mail/Create", RequestType.POST, mail)
            .then((res) => {
                this.dispatch(addmail(res));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly EditMailRequest = (mail: MailObject, disableLoadingAfterFinish: boolean = true) => new Promise<MailObject>((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJson<MailObject>(MailObject, "Mail/Edit", RequestType.POST, mail)
            .then((res) => {
                this.dispatch(editMail(res));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly DeleteMailRequest = (mail: MailObject, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown("Mail/Delete", RequestType.POST, mail.id)
            .then((res) => {
                this.dispatch(removeMail(mail));
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly SendTemplateToAllPlayersRequest = (mail: MailObject, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown(`Mail/PushTemplateToAllPlayers?mailTemplateId=${mail.id}`, RequestType.POST)
            .then((res) => {
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly SendFromTemplatesToPlayerRequest = (mail: MailObject, playerId: string, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown(`Mail/PushFromTemplatesToPlayer?mailTemplateId=${mail.id}&playerId=${playerId}`, RequestType.POST)
            .then((res) => {
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });

    public static readonly SendCustomMailToPlayerRequest = (mail: MailObject, playerId: string, disableLoadingAfterFinish: boolean = true) => new Promise((resolve, reject) => {
        this.dispatch(setLoading(true));

        requestJSONUnknown(`Mail/PushToPlayer?playerId=${playerId}`, RequestType.POST, mail)
            .then((res) => {
                this.disableLoading(disableLoadingAfterFinish);

                resolve(res);
            })
            .catch((e) => {
                this.dispatch(setLoading(false));
                reject(e);
            });
    });
}