import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import MailObject from '../../Models/MailModel/MailObject';

interface IMailCache {
    mails: Array<MailObject>
};

const initialState: IMailCache = {
    mails: []
};

export const mailCache = createSlice({
    name: "mails",
    initialState: initialState,
    reducers: {
        addmails: (state, mails: PayloadAction<MailObject[]>) => {
            state.mails.splice(0);

            mails.payload.forEach(mail => {
                state.mails.push(mail);
            });
        },
        editMail: (state, mail: PayloadAction<MailObject>) => {
            const index = state.mails.findIndex(m => m.id === mail.payload.id);

            if (index === -1) {
                return console.error(`Reward didn't find a match`);
            }

            state.mails[index] = mail.payload;
        },
        addmail: (state, mail: PayloadAction<MailObject>) => {
            state.mails.push(mail.payload);
        },
        removeMail: (state, mail: PayloadAction<MailObject>) => {
            state.mails = state.mails.filter(m => m.id != mail.payload.id);
        }
    }
});

export const { addmail, addmails, removeMail, editMail } = mailCache.actions;

export default mailCache.reducer;