import React, { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { Button } from '@mui/material';
import { ForumMode } from '../../util/forumMode';
import { FetchHandler } from '../../util/fetchHandler';
import MailObject from '../../Models/MailModel/MailObject';
import MailCard from './components/MailCard';
import { MailModal } from './components/MailModal';

const MailPage = (props: any) => {

    const [selectedMail, setSelectedMail] = useState<MailObject>(null);
    const [forumMode, setForumMode] = useState(ForumMode.Create);
    const [showModal, setShowModal] = useState(false);

    const mails = useAppSelector(state => state.mailCache.mails);

    const ToggleModal = (val: boolean) => {
        setShowModal(val);
    }

    const CreateNewMailTemplate = () => {
        setForumMode(ForumMode.Create);
        setShowModal(true);
    }

    const CreateNewMailForPlayer = () => {
        setSelectedMail(null);
        setForumMode(ForumMode.Custom);
        setShowModal(true);
    }

    const EditMail = (mail: MailObject) => {
        setSelectedMail(mail);
        setForumMode(ForumMode.Edit);
        setShowModal(true);
    }

    const DeleteMailTemplate = (mail: MailObject) => {
        FetchHandler.DeleteMailRequest(mail)
            .then(() => FetchHandler.FetchMails())
            .catch(console.error);
    }

    const SendTemplateToAllPlayers = (mail: MailObject) => {
        FetchHandler.SendTemplateToAllPlayersRequest(mail)
            .then(() => FetchHandler.FetchMails())
            .catch(console.error);
    }

    const RenderModal = () => {
        return showModal ? <MailModal forumMode={forumMode} mail={selectedMail} toggleModal={ToggleModal} /> : <></>
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h1 className="fw-bold">Mail Templates</h1>

                <div style={{ display: "flex", width: "500px", flexDirection: "row", justifyContent: "space-around" }}>
                    <Button sx={{ maxWidth: "200px" }} variant="contained" onClick={CreateNewMailForPlayer}>
                        Create and Send Mail To Player
                    </Button>

                    <Button variant="contained" onClick={CreateNewMailTemplate}>
                        Add New Mail Template
                    </Button>
                </div>
            </div>

            <hr />

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignContent: "center" }}>
                {
                    mails.map(mail => (<MailCard 
                        mail={mail} 
                        callbackEdit={() => { EditMail(mail) }} 
                        callbackDelete={() => DeleteMailTemplate(mail)}
                        callbackSendToAllPlayers={() => SendTemplateToAllPlayers(mail)} 
                        callbackSendTemplateToPlayer={() => {}}
                    />))
                }
            </div>

            <RenderModal />
        </>
    )
}

export default MailPage;