import React from "react";

import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import RedeemIcon from '@mui/icons-material/Redeem';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SellIcon from '@mui/icons-material/Sell';
import RecentActors from '@mui/icons-material/RecentActors';

import { MissionsPage } from "./pages/MissionPage/MissionsPage";
import { Home } from "./pages/HomePage/Home";
import BattlePassPage from "./pages/BattlePassPage/BattlePassPage";
import { RewardsPage } from "./pages/RewardsPage/RewardsPage";
import { RewardProfilePage } from "./pages/RewardProfiles/RewardProfilePage";
import LevelPage from "./pages/LevelPage/LevelPage";
import BundlePage from "./pages/BundlePage/BundlePage";
import MailPage from "./pages/MailsPage/MailPage";
import { Email } from "@mui/icons-material";

export class AppRoute {
  public name: string = "";
  public path: string = "";
  public index: boolean = false;
  public element: JSX.Element = null;
  public icon?: JSX.Element = null;
  public showInNav?: boolean = false;
}

const AppRoutes: AppRoute[] = [
  {
    name: "Home",
    path: '/',
    index: true,
    element: <Home />,
    icon: <HomeIcon />,
    showInNav: true
  },
  {
    name: "Missions",
    path: '/missions',
    index: false,
    element: <MissionsPage />,
    icon: <AssignmentIcon />,
    showInNav: true
  },
  {
    name: "BattlePass",
    path: "/battlepass",
    index: false,
    element: <BattlePassPage />,
    icon: <LibraryAddCheckIcon />,
    showInNav: true
  },
  {
    name: "Rewards",
    path: "/rewards",
    index: false,
    element: <RewardsPage />,
    icon: <RedeemIcon />,
    showInNav: true
  },
  {
    name: "Profiles",
    path: "/profiles",
    index: false,
    element: <RewardProfilePage />,
    icon: <RecentActors />,
    showInNav: true
  },
  {
    name: "Levels",
    path: "/levels",
    index: false,
    element: <LevelPage />,
    icon: <MilitaryTechIcon />,
    showInNav: true
  },
  {
    name: "Bundles",
    path: "/bundles",
    index: false,
    element: <BundlePage />,
    icon: <SellIcon />,
    showInNav: true
  },
  {
    name: "Mails",
    path: "/mail",
    index: false,
    element: <MailPage />,
    icon: <Email />,
    showInNav: true
  }
];

export default AppRoutes;