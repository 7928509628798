import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import MiniDrawer from './MiniDrawer';
import { useAppSelector } from '../store/hooks';
import { FetchHandler } from '../util/fetchHandler';
import { useLocation } from 'react-router-dom';

export const Layout = (props) => {
  const payload = useAppSelector((state) => state.authenticationCache.payload);

  const location = useLocation();

  useEffect(() => {
    FetchHandler.getValidationToken(false)
      .then(() => FetchHandler.getPlayFabContent(false))
      .then(() => FetchHandler.getBattlepasses(false))
      .then(() => FetchHandler.getMissions(false))
      .then(() => FetchHandler.getRewardProfiles(false))
      .then(() => FetchHandler.getRewards(false))
      .then(() => FetchHandler.getLevels(false))
      .then(() => FetchHandler.FetchMails())
      .catch(console.error);
  }, [location.pathname, payload]);

  const drawContent = () => {
    if (payload === null || payload === "") {
      return <h3 style={{ margin: "auto", textAlign: "center" }}>Authentication Required</h3>
    }

    return <Container tag="main">{props.children}</Container>
  }

  return (
    <div>
      <MiniDrawer content={drawContent()} />
    </div>
  );
}
