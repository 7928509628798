import React, { MouseEventHandler } from "react";

import { Button, Card } from "@mui/material";
import MailObject from "../../../Models/MailModel/MailObject";

export class MailCardProps {
    public mail: MailObject = null;
    public callbackEdit: MouseEventHandler<HTMLButtonElement> = () => { };
    public callbackDelete: MouseEventHandler<HTMLButtonElement> = () => { };
    public callbackSendToAllPlayers: MouseEventHandler<HTMLButtonElement> = () => { };
    public callbackSendTemplateToPlayer: MouseEventHandler<HTMLButtonElement> = () => { };
}

const MailCard = ({ 
    mail, 
    callbackDelete, 
    callbackEdit, 
    callbackSendToAllPlayers,
    callbackSendTemplateToPlayer,
}: MailCardProps) => {
    return (
        <Card className="card m-1" key={mail.id}>
            <div className="card-body">
                <h5 className="card-title">{mail.title}</h5>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <p>{mail.content}</p>
                </div>

                <hr />

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button type={"button"} variant="contained" onClick={callbackEdit}>Edit</Button>

                    <Button type={"button"} variant="contained" onClick={callbackSendToAllPlayers}>Send To All Players</Button>
                    <Button type={"button"} variant="outlined" color="error" onClick={callbackDelete}>Delete</Button>
                </div>
            </div>
        </Card>
    );
}

export default MailCard;